import React, { useEffect, useState } from "react";
import BannerVideo from "./BannerVideo";
import ViewProjects from "./ViewProjects";
import OurAchievement from "./OurAchievement";
import FeatureProjects from "./FeatureProjects";
import Testimonials from "./Testimonials";
import Slider from "react-slick";
import { axiosInstance, Config } from "../../../config";
import Loader from "../../Common/Loader";
import GetInTouch from "../../Common/GetInTouch";
import MakeAppointment from "../../Common/MakeAppointment";

const Index = () => {
  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  const [home_detail, setHomeDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getHomePage();
  }, []);
  function getHomePage() {
    setLoading(true);
    axiosInstance
      .get(`homepage`)
      .then((res) => {
        const Home = res?.data?.home_page;
        setHomeDetail(Home);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }


  return (
    <>
      {loading && <Loader />}
      <BannerVideo
        banners={home_detail?.banner_section}
        contact={home_detail?.contact_details?.contact_numbers}
        buttonText={home_detail?.banner_section_text}
      />
      <ViewProjects projects={home_detail?.categories} title={home_detail?.project_button} />
      
      <OurAchievement
        achievements={home_detail?.achievement_section}
        developer_count={home_detail?.achievement_developer_count}
        project_count={home_detail?.achievement_project_count}
        client_counts={home_detail?.achievement_customer_count}
      />
      <MakeAppointment cta={home_detail?.call_to_action} />
      <FeatureProjects projects={home_detail?.project} projectdetail={home_detail?.project_button} />

      <Testimonials
        testimonial={home_detail?.testimonial}
        titles={home_detail?.testimonial_title}
        backImage={home_detail?.testimonial_bg?.Test_bg}
      />

      <div className="section-padding" style={{ backgroundColor: "#f8f5f0" }}>
        <div className="container">
          <div className="row align-items-center justify-space-between">
            <div className="col-md-6 side-info">
              <div className="col-md-10">
                <p>{home_detail?.contact_details?.longitude}</p>
                <div className="d-flex section gap-3 align-items-center mb-2">
                  <a className="icon">
                    <i className="bi bi-geo-alt"></i>
                  </a>
                  <a
                    className="text-decoration-none text-dark fs-5"
                    href={`${home_detail?.contact_details?.latitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {home_detail?.contact_details?.contact_addresses}
                  </a>
                </div>
                <div className="d-flex section gap-3 align-items-center mb-2">
                  <a className="icon">
                    <i className="bi bi-telephone"></i>
                  </a>
                  <div>
                    <a
                      className="text-dark text-decoration-none"
                      href={`callto:${home_detail?.contact_details?.contact_numbers}`}
                    >
                      {home_detail?.contact_details?.contact_numbers}
                    </a>
                  </div>
                </div>
                <div className="d-flex section gap-3 align-items-center  mb-2">
                  <a className="icon">
                    <i className="bi bi-envelope"></i>
                  </a>
                  <div>
                    <a
                      className="text-dark text-decoration-none"
                      href={`mailto:${home_detail?.contact_details?.contact_mails}`}
                    >
                      {home_detail?.contact_details?.contact_mails}
                    </a>
                  </div>
                </div>
              </div>

              <div className="my-4">
                  <h4 className="fw-bold">Working Hours</h4>
                  <h6>{home_detail?.contact_details?.working_days}</h6>
                  <h6>{home_detail?.contact_details?.working_hours}</h6>
                </div>

              <div >
                <span className="subTitle">
                  {home_detail?.contact_titles?.contact_head_title}
                </span>
                <h2 className="top-build mb-5">
                  {home_detail?.contact_titles?.contact_head_subtitle}
                </h2>
                <div className="">
                  <Slider {...settings}>
                    {home_detail?.contact_slider_section?.map((build, i) => {
                      return (
                        <div className="slide" key={i}>
                          <img
                            src={`${Config.imageUrl}/sliders/${build.image}`}
                          ></img>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <GetInTouch section={'Home'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
