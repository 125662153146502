import React, { useEffect, useRef, useState } from "react";
import { axiosInstance, Config } from "../../../config";
import PaymentForm from "./PaymentForm";

const ProjectEnquiry = ({ Labels , floorplan, users, SelectedType , details, section, slug, content, projectContent , showRegistrationForm , setshowRegistrationForm }) => {

    const [show, setShow] = useState(false)
    const divRef = useRef(null)

    useEffect(() => {
        if (show)
            window.scrollTo(0, divRef?.current?.offsetTop + 180);
    }, [show])

    return (
        <div ref={divRef}>
            <div className={`Price-Banner`}>
                <div style={{backgroundColor:"rgb(34, 34, 34)"}} className="py-4">
                    <div className="container py-4">
                        <div className="row d-flex align-items-center">
                            <h4 className="col-xl-8 mb-0" style={{color:"#EEEEEE"}}>
                                {details?.project_amount_container_text} <span className="fw-bolder text-white fs-3">{section}</span> is 
                                <span className="blink-price ms-2">₹{content?.registration_amount}.</span>
                            </h4>
                            <div className="col-xl-4">
                                <button type="button" onClick={() => setShow(!show)} style={{width:"350px"}} className="mt-5 mt-xl-0 submit-btn d-flex align-items-center px-4 text-uppercase fw-bold">
                                    {content?.btn_text}
                                    <i className="bi bi-arrow-right ms-4 fs-5 rounded-circle move_arrow"
                                    ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {
            ( showRegistrationForm || show) &&
            <div style={{backgroundColor:"#e5d1ae"}}>
                <div  className="container py-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-10 side-info">
                            <PaymentForm Labels={Labels} floorplan={floorplan} Users={users} SelectedType={SelectedType} run={setShow} details={details} projectName={section} isProject={true} slug={slug}
                                content={content} setShow={setShow} projectContent={projectContent} divRef={divRef} setshowRegistrationForm={setshowRegistrationForm} />
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
    );
};

export default ProjectEnquiry;
