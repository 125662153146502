import axios from "axios";

// https://ztpl.net/uberluxuryconnect

export const Config = {
  appUrl: "/",
  imageUrl: "https://www.uberluxuryconnect.com/admin/assets/front/img",
  pdfUrl: "https://www.uberluxuryconnect.com/admin/assets/lfm/pdfs",
  
  // appUrl: "/uberluxuryconnect/",
  // imageUrl:"https://ztpl.net/uberluxuryconnect/admin/assets/front/img",
  // pdfUrl: "https://ztpl.net/uberluxuryconnect/admin/assets/lfm/pdfs",
  
  calendlyUrl: "https://calendly.com/uberluxuryconnect2024/schedule-a-visit",
  // calendlyUrl: "https://calendly.com/john-zithas/schedule-a-visit"
};

export const axiosInstance = axios.create({
  // baseURL: "https://192.168.0.2/uberluxuryconnect/admin/api/",
  baseURL: "https://www.uberluxuryconnect.com/admin/api/",
  // baseURL: "https://ztpl.net/uberluxuryconnect/admin/api/",
  headers: {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*'
  },
});