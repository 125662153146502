import { useEffect, useState } from "react";
import Loader from "../../Common/Loader";
import Banner from "../../Common/Banner";
import { axiosInstance } from "../../../config";

const Terms = () =>{

    const [Loading,setLoading] = useState(false);
    const [bannerDetail,setBannerDetail] = useState({})

    useEffect(() => {
        getData();
    }, []);
    
    function getData() {
        setLoading(true);
        axiosInstance
            .get(`footer`)
            .then((res) => {
                setBannerDetail(res?.data?.footer?.terms_conditions_section)
            })
            .catch((err) => console.log(err))
            .finally(() => {
            setLoading(false);
            });
    }

    return(
        <div>
            {Loading && <Loader />}

            <Banner/>

            <div className="section-padding bg-light-gold">
                <div className="container">
                    <h1 className="mb-4">{bannerDetail?.terms_title}</h1>
                    <p style={{textAlign:'justify'}} dangerouslySetInnerHTML={{__html:bannerDetail?.terms_description}}/>
                </div>
            </div>
        </div>
    )

}

export default Terms;