import React, { useEffect, useState } from 'react'
import "../../../assets/css/PressRelease.css"
import Banner from '../../Common/Banner';
import { axiosInstance, Config } from '../../../config';
import Loader from '../../Common/Loader';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const PressRelease = () => {

    const [pressData, setPressData] = useState(null)
    const [loading, setLoading] = useState(false)

    const { slug } = useParams()

    useEffect(() => {
        const getPressData = async () => {
            setLoading(true)
            try {
                const response = await axiosInstance.get(`/pressrelease/${slug}`)
                if (response.data.success)
                    setPressData(response.data)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

        getPressData()
    }, [])


    return (
        <>
            {loading && <Loader />}
            <Banner
                subtitle={pressData?.data?.press_header_title}
                title={pressData?.data?.press_header_subtitle}
                backImage={pressData?.data?.press_header_bg}
            />

            <section className="py-5">
                <div className="container py-md-4">

                    <div className="row align-items-center">
                        <div className='col-12'>
                            <img
                                src={`${Config.imageUrl}/${pressData?.press_release?.image}`} // Replace with your banner image URL
                                alt="Press Release Banner"
                                className="press-banner"
                            />

                            <div className="mt-4  px-md-4">
                                <h1 className="press-title text-green mt-md-0 mt-4">{pressData?.press_release?.title}</h1>
                                <div>
                                    <h6 className="fw-normal text-green mt-md-0 mt-4">
                                        <i className='bi bi-calendar me-2'></i>{moment(pressData?.press_release?.created_at).format("DD MMMM, YYYY")}</h6>
                                </div>
                                <div className="press-content mt-4">

                                    <div style={{textAlign:'justify'}} dangerouslySetInnerHTML={{ __html: pressData?.press_release?.description }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="news-page">
                        <div className="content-wrapper">
                            <img
                                src="https://images.pexels.com/photos/1486785/pexels-photo-1486785.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Replace with your banner image URL
                                alt="Press Release Banner"
                                className="press-banner"
                            />
                            <h1 className="press-title text-green">Introducing Our Landmark Property Project</h1>

                            <div className="press-content mt-4">
                                <p>
                                    We are proud to unveil our latest property development project, *The Grand Horizon Estates*. Located in the heart of the city, this state-of-the-art project combines luxury living with sustainable design principles. Featuring over 200 premium apartments, retail spaces, and green recreational areas, the project is set to redefine urban living.
                                </p>
                                <p>
                                    The Grand Horizon Estates will offer unmatched amenities, including a rooftop infinity pool, wellness center, and smart home integrations. Designed by renowned architects and developed with environmentally conscious practices, the project aims to deliver the ultimate living experience.
                                </p>
                                <p>
                                    Construction is set to begin in Q1 2025, with project completion expected by the end of 2027. Pre-bookings will open soon for interested buyers and investors.
                                </p>
                                <p>
                                    For more details, please contact our press team at <a href="mailto:info@deendayalplot.com">info@deendayalplot.com</a>.
                                </p>
                            </div>
                        </div>
                    </div> */}

                </div>
            </section>
        </>
    )
}

export default PressRelease