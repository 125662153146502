import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance, Config } from "../../config";
import Slider from "react-slick";
import DeendayalLogo from "../../assets/Images/deendayal-logo.png"
import HarayanaLogo from "../../assets/Images/harayana-logo.jpg"
import PropuserLogo from "../../assets/Images/propuser.png"
import WhatsappIcon from "./WhatsappIcon";
import NewsLetter from "./NewsLetter";

const Footer = () => {
  const [footerDetails, setFooterDetails] = useState({});
  const [contactDetails, setContactDetails] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [subscribeData, setSubscribeData] = useState({});
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    if (Object.keys(footerDetails)?.length == 0) {
      getFooter();
    }
  }, []);
  function getFooter() {

    axiosInstance
      .get(`footer`)
      .then((res) => {
        const data = res?.data?.footer;
        setFooterDetails(data?.footer_section);

        const locs = []
        data?.location?.forEach(loc => {
          if (!locs.some(item => item.state === loc.state)) {
            locs.push(loc)
          }
        });

        const sortedArray = locs.sort((a, b) => b.projects.length - a.projects.length);
        setContactDetails(sortedArray)
        setLocations(data?.location)
        // setContactDetails(data?.contact_section);
        const locations = data?.contact_section?.Text_1?.split(",");
        const locationsNo = data?.contact_section?.Value_1?.split(",");
        const result = locations.map((name, index) => ({
          name: name,
          no: locationsNo[index],
        }));

        const footerIcons = data?.footer_section?.icon?.split(",");
        const footerLink = data?.footer_section?.link?.split(",");
        const merge = footerIcons.map((name, index) => ({
          icon: name,
          link: footerLink[index],
        }));
        setSocialLinks(merge);

        setSubscribeData(data?.newsletter_section)
      })
      .catch((err) => console.log(err))
  }


  var settings = {
    arrows: false,
    dots: false,
    infinite: contactDetails?.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <div className="bg-gold">
        <div className="container pt-4 pb-4">
          <Slider {...settings}>
            {contactDetails?.map((rera, i) => {

              return (
                <div key={i}>
                  <div className="rera-slider text-center">{rera?.state} - {rera?.rera_no ? rera?.rera_no : 'NA'}</div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>

      <div className="footer">
        <div className="col-md-12">
          <div className="d-flex justify-content-between mobile-flex">
            <div>
              <div  >

                <div className="mb-5">
                  <NewsLetter subscribeData={subscribeData} />
                </div>
                <div className="mb-4">
                  <h4 className="project-location">Quick Links</h4>
                  <ul className="locations-section">
                    <li><Link to={`${Config.appUrl}`}>Home</Link></li>
                    <li><Link to={`${Config.appUrl}about-us`}>About Us</Link></li>
                    <li><Link to={`${Config.appUrl}all-projects`}>All Projects</Link></li>
                    <li><Link to={`${Config.appUrl}locations`}>Locations</Link></li>
                    <li><Link to={`${Config.appUrl}blogs`}>Blog</Link></li>
                    <li><Link to={`${Config.appUrl}contact-us`}>Contact Us</Link></li>
                    <li><Link to={`${Config.appUrl}news`}>News</Link></li>
                    <li><Link to={`${Config.appUrl}refund-policy`}>Refund Policy</Link></li>
                    <li><Link to={`${Config.appUrl}terms-conditions`}>Terms & Conditions</Link></li>
                    <li><Link to={`${Config.appUrl}privacy-policy`}>Privacy Policy</Link></li>
                  </ul>
                </div>

              </div>
              <div className="mb-4" >
                <h4 className="project-location">Locations</h4>
                <ul className="locations-section">
                  {locations?.map((rera, i) => {
                    return (
                      <li key={i} className="">
                        <Link to={`${Config.appUrl}location/${rera.slug}`}>
                          {rera?.city}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>

            </div>
            <div className="col-md-6">
              <div>
                <div className="address">
                  <h4 className="mb-1">Social Media</h4>
                  <div className="social d-flex gap-2 mt-0 pt-2">
                    {socialLinks?.map((social, i) => {
                      return (
                        <div key={i}>
                          <a
                            className=" text-white "
                            target="_blank"
                            href={social?.link}
                          >
                            <i className={social?.icon}></i>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* <div className="mt-4">
                  <p dangerouslySetInnerHTML={{ __html: footerDetails?.disclaimer }} className="mb-0 font-Gilda" />
                </div> */}
                <div className="my-4 py-2">
                  <div>
                    <a target="_blank" href="https://www.deendayalplot.com" className="me-sm-3 me-2">
                      <img src={DeendayalLogo} width={60} className="border-roundeds" />
                    </a>
                    <a target="_blank" href="https://haryanaaffordablehousingshop.com" className="me-sm-3 me-2">
                      <img src={HarayanaLogo} width={60} className="border-roundeds" />
                    </a>
                    <a target="_blank" href="https://www.propusers.com">
                      <img src={PropuserLogo} width={158} className="border-roundeds" />
                    </a>
                  </div>
                </div>
                {/* <p className="mt-3">This website is operated under <a style={{ color: "#aa8453", textDecoration: "none" }} target="_blank" href="https://www.Propusers.com">
                  <strong>Propusers.com </strong>
                </a>, owned by RTN Propusers Pvt Ltd. All licenses and RERA registrations are held under RTN Propusers Pvt Ltd ( CIN/License No. 70109DL2020PTC368188), Registered under RERA: UPRERAAGT19439 ).
                </p> */}
                <div className="mt-3 disclaimer_text" style={{textAlign:'justify'}}
                  dangerouslySetInnerHTML={{ __html: footerDetails?.disclaimer }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#222" }} className="pt-5 pb-2 px-md-5 px-4">
        <div
          className="mb-0 text-white text-center disclaimer_text" style={{textAlign:'justify'}}
          dangerouslySetInnerHTML={{ __html: footerDetails?.copyright_text }}
        />
      </div>


      <WhatsappIcon whatsappNumber={footerDetails?.whatsapp} />

    </div >
  );
};
export default Footer;
