import React, { useEffect, useState } from 'react'
import "../../../assets/css/PressRelease.css"
import Banner from '../../Common/Banner';
import { axiosInstance, Config } from '../../../config';
import Loader from '../../Common/Loader';
import moment from 'moment';
import { Link } from 'react-router-dom';

const PressReleaseList = () => {

    const [pressData, setPressData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getPressData = async () => {
            setLoading(true)
            try {
                const response = await axiosInstance.get(`/pressrelease`)
                if (response.data.success)
                    setPressData(response.data)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

        getPressData()
    }, [])

    return (
        <>
            {loading && <Loader />}
            <Banner
                subtitle={pressData?.data?.press_header_title}
                title={pressData?.data?.press_header_title}
                backImage={pressData?.data?.press_header_bg}
            />

            <section className="py-5">
                <div className="container py-md-4">

                    <div className="row align-items-start">
                        {
                            pressData?.press_release?.map(press => {
                                return (
                                    <div className="col-md-4">
                                        <div className='press_card p-2 mb-5'>
                                            <Link to={`${Config.appUrl}news-details/${press?.slug}`} className='text-decoration-none text-dark'>
                                                <div>
                                                    <img
                                                        src={`${Config.imageUrl}/${press?.image}`}
                                                        alt="Press Release Banner"
                                                        className="press-banner"
                                                        style={{height: "250px"}}
                                                    />
                                                </div>
                                                <div>
                                                    <h4 className=" text-green mt-md-0 mt-4 mb-3 ">{press?.title}</h4>
                                                </div>
                                                <div>
                                                    <h6 className="fw-normal text-green mt-md-0 mt-4">
                                                        <i className='bi bi-calendar me-2'></i>{moment(press?.created_at).format("DD MMMM, YYYY")}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </section>
        </>
    )
}

export default PressReleaseList