import React from "react";
import Slider from "react-slick";
import Quote from "../../../assets/Images/quot.png";
import { Config } from "../../../config";

const Testimonials = ({ testimonial, titles, backImage }) => {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
  };

  return (
    <div
      className="testimonials"
      style={{
        backgroundImage: `url(${Config.imageUrl}/${backImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="section-padding position-relative">
        <div className="container ">
          <div className="bg-overlay"></div>
          <div className="testimon-content">
            <div>
              <span className="subTitle text-white">
                {titles?.testimonial_title}
              </span>
              <h2>{titles?.testimonial_subtitle}</h2>
              <Slider {...settings}>
                {testimonial?.map((test, i) => {
                  return (
                    <div key={i} className="pt-4 pb-5 mb-3">
                      <p className="text-white">{test.comment}</p>
                      <div className="d-flex justify-space-between">
                        <div className="d-flex info gap-4 align-items-center">
                          <img
                            src={`${Config.imageUrl}/${test.image}`}
                            className="author-img"
                          />
                          <div>
                            <h4>{test.name}</h4>
                            {/* <span>Guest User</span> */}
                          </div>
                        </div>
                        <div className="text-right">
                          <img
                            src={Quote}
                            alt="Quote Img"
                            width="80"
                            className="quote-width"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Testimonials;
