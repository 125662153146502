import React, { useEffect, useRef, useState } from "react";
import { axiosInstance, Config } from "../../../config";
import Loader from "../../Common/Loader";
import { notification } from "antd";
import secureLocalStorage from "react-secure-storage";

const PaymentForm = ({Labels,Users, floorplan, setshowRegistrationForm , SelectedType, run , details, projectName, content, slug, setShow, projectContent, divRef }) => {

  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [aadharNo, setAadharNo] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [panNo, setPanNo] = useState("");
  const [pan, setPan] = useState("");
  const [amount , setamount] = useState('') 

  const [type_size_options, setTypeSizeOptions] = useState([]);
  const [type_size, setTypeSize] = useState({});

  const [pay_method, setPay_method] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  // const [utr, setUtr] = useState("");
  // const [rrn, setRrn] = useState("");

  const [SourceName , setSource] = useState('')
  const [RefrenceID,setRefrenceID] = useState('')

  const [payment_receipt, setPaymentReceipt] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [registrationAmount,setRegistrationAmount] = useState('');

  const [step, setStep] = useState(1);
  const [contact_id, setContactId] = useState(null);

  useEffect(() => {
    const ID = secureLocalStorage.getItem("ContactID");
    if(ID){
      setContactId(ID);
    }
    
  },[])

  const aadhar_ref = useRef(null)
  const pan_ref = useRef(null)

  useEffect(() => {
    if(SelectedType){
      setTypeSize({
        type:SelectedType?.type,
        size : SelectedType?.size
      })
    }
  },[SelectedType])  

  useEffect(() => {
    setRegistrationAmount(floorplan.map((item) => {
      const parts = item?.split(',');  // Split item by commas
      const type = parts[0];           // Get the first part (type)
      const amount = parts[2];         // Get the third part (amount)
  
      // Compare the type with your desired value (e.g., 'desiredType')
      if (type == type_size?.type) {
        return amount;  // If the type matches, return the amount
      } else {
        return null;    // If the type doesn't match, you can return null or any other value
      }
    }));
  }, [type_size, floorplan]);  

  useEffect(() => {
    if (floorplan) {
      console.log(floorplan);
      setTypeSizeOptions(floorplan.map((item) => {
        return ({
          type: item.split(',')[0],
          size: item.split(',')[1]
        })
      }))
    }
  }, [floorplan])
  

  useEffect(() => {
    const Data = secureLocalStorage?.getItem("UserData");
    if(Data !== null){
      setName(Data?.name);
      setFatherName(Data?.father_name);
      setEmail(Data?.email);
      setPhone(Data?.phone);
      setAddress(Data?.address);
      setDob(Data?.dob);
      setAadharNo(Data?.aadharNo);
      setPanNo(Data?.panNo)
      setStep(2);
    }
  },[])

  const [User , setUser] = useState('');

  useEffect(() => {
    if(RefrenceID.length >= 2){
      setUser(Users.filter(item => String(item?.ref_id)?.toLowerCase() === String(RefrenceID)?.toLowerCase()));
      console.log(User);
    }
  },[RefrenceID])

  const validation1 = () => {
    const newErrors = {};

    if (step === 1) {
      if (!name) newErrors.fname = "Name is required";
      if (!fatherName) newErrors.fatherName = "Father/Husband Name is required";
      if (!dob) newErrors.dob = "DOB is required";
      if (!address) newErrors.address = "Address is required";

      if (!email) {
        newErrors.email = "Email is required";
      } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        newErrors.email = "Invalid email format";
      }

      if (!phone) {
        newErrors.phone = "Phone no. is required";
      } else if (phone.length > 15 || phone.length < 7) {
        newErrors.phone = "Invalid Phone no. length";
      }

      if(aadhar){
        if (!aadharNo)
        newErrors.aadharNo = "Aadhar No is required";
      }

      if(pan){
        if (!panNo)
        newErrors.panNo = "Pan No is required";
      }

      if(aadharNo){
        if (!aadhar)
        newErrors.aadhar = "Aadhar Card is required";
      }

      if(panNo){
        if (!pan)
        newErrors.pan = "Pan Card is required";
      }
    }
    else {
      if (!type_size?.size) newErrors.size = "Area Size is required";
      if (!type_size?.type) newErrors.type = "Type is required";
      if (!dob) newErrors.dob = "DOB is required";
      if (!pay_method) newErrors.pay_method = "Please select payment method";
      if (!referenceNo) newErrors.referenceNo = "Reference no. is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onHandleSubmit = async (isNext) => {

    if (validation1()) {
      setLoading(true);
      try {
        setLoading(true);
        const formData = new FormData();
        if (step === 1) {
          formData.append("name", name);
          formData.append("father_name", fatherName);
          formData.append("phone", phone);
          formData.append("email", email);
          formData.append("address", address);
          formData.append("dob", dob);
          formData.append("project", slug);
          formData.append("step", step);
          formData.append("Source", SourceName);
          formData.append("RefrenceID" , RefrenceID);

          if (contact_id){
            formData.append("id", contact_id);
          }

          const data = {
            name : name,
            father_name : fatherName,
            phone : phone,
            email : email,
            address :address,
            dob :dob ,
            aadhar_no : aadharNo,
            pan_no : panNo
          }
          console.log(formData);
          secureLocalStorage.setItem("UserData" , data);
        }
        else {
          formData.append("type", type_size.type);
          formData.append("size", type_size.size);
          formData.append("referenceNo", referenceNo);
          formData.append("payment_method", pay_method);
          formData.append("receipt", payment_receipt);
          formData.append("message", message);
          formData.append("step", step);
          formData.append("id", contact_id);
          formData.append("aadhar_no", aadharNo);
          formData.append("aadhar", aadhar);
          formData.append("pan", pan);
          formData.append("pan_no", panNo);

          const offer = details.offers?.[0];
          if (offer && offer.end_date && new Date(offer.end_date) > new Date()) {
              formData.append("offer_id", offer.id); // Assuming 'id' exists in offer object
          }
        }

        const res = await axiosInstance.post("paymentdetails", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.data.status === 200) {
          if (isNext) {
            setStep(2)
            setTimeout(() => window.scrollTo(0, divRef?.current?.offsetTop), 200)
            setContactId(res.data.contactid)
            secureLocalStorage.setItem("ContactID",res.data.contactid);

            notification.open({
              message: (
                <div className="d-flex align-items-center">
                  <div className="pr-20">
                    <i
                      className="bi bi-check2-circle text-success me-2"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </div>
                  <div className="succes-message">  Data Saved Successfully ! </div>
                </div>
              ),
              description: "Now Fill out the further details",
              placement:'top'
            });

          }
          else {
            secureLocalStorage.removeItem("UserData");
            secureLocalStorage.removeItem("ContactID");
            setShow(false)
            notification.open({
              message: (
                <div className="d-flex align-items-center">
                  <div className="pr-20">
                    <i
                      className="bi bi-check2-circle text-success me-2"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </div>
                  <div className="succes-message">Thanks for reaching out to us! </div>
                </div>
              ),
              description: "We will contact you with a response shortly.",
              placement:'top'
            });
          }

        }
        setLoading(false);
      } catch (error) {
        notification.open({
          message: (
            <div className="d-flex align-items-center">
              <div className="pr-20">
                <i
                  className="bi bi-x-circle text-success me-2"
                  style={{ fontSize: "20px" }}
                ></i>
              </div>
              <div className="succes-message">Sorry Something went Wrong. </div>
            </div>
          ),
          description: error?.message,
          placement:'top'
        });
        setLoading(false);
      }
    } else {
      window.scrollTo(0, divRef?.current?.offsetTop + 300);
    }
  };

  const handleUploadDocument = (e, setFile, type, doc_ref) => {
    const file = e.target.files[0];
      setFile(file)
      setErrors(prev => ({ ...prev, [type]: "" }))
  }

  return (
    <>
      {loading && <Loader />}
      {/* <form onSubmit={onHandleSubmit}> */}
      <div className="contact-form">
        <div className="d-flex justify-content-between">
          <h4 className="mb-5">
            {details?.project_amount_container_text} <span className="fw-bold" style={{ color: "#aa8453" }}>{projectName}</span> is ₹
            {content?.registration_amount}.</h4>
          <i style={{cursor:"pointer"}} onClick={() => {
            run(false)
            setshowRegistrationForm(false)  
          }} className="bi bi-x-circle fs-5"></i>
        </div>

        {
          step === 1 &&
          <>
            <div className="row">
              <div className="subTitle mb-2">Personal Details</div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Full Name <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    placeholder="Enter Full Name"
                    className=" form-luxury"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyPress={(e) => {
                      if (/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.fname && (
                    <span className=" text-danger span-error">{errors.fname}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Father/Husband Name <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    placeholder="Enter Father/Husband Name"
                    className=" form-luxury"
                    value={fatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                    onKeyPress={(e) => {
                      if (/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.fatherName && (
                    <span className=" text-danger span-error">{errors.fatherName}</span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Email Address <span className="text-danger">*</span></label>
                  <input
                    type="email"
                    placeholder="Enter Email Address"
                    className=" form-luxury"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}

                  />
                  {errors.email && (
                    <span className=" text-danger span-error">{errors.email}</span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Mobile Number <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    placeholder="Enter Mobile No."
                    className=" form-luxury"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    maxLength={10}

                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.phone && (
                    <span className=" text-danger span-error">{errors.phone}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>DOB <span className="text-danger">*</span></label>
                  <input
                    type="date"
                    className=" form-luxury"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                  {errors.dob && (
                    <span className=" text-danger span-error">{errors.dob}</span>
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Address <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    placeholder="E.g. 123, Brahmin Faliya, Chhani, Vadodara, Gujarat 391740"
                    className=" form-luxury"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  {errors.address && (
                    <span className=" text-danger span-error">{errors.address}</span>
                  )}
                </div>
              </div>

            </div>

            <div className="row mt-2">
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>How did you hear about us</label>
                  <select className="form-luxury"
                    value={SourceName}
                    onChange={(e) => setSource(e.target.value)}
                    style={{ padding: "20px 15px" }} >
                    <option value="">Select Info. Source</option>
                    <option value="Google Search">Google Search</option>
                    <option value="Facebook / Instagram">Facebook / Instagram</option>
                    <option value="YouTube">YouTube</option>
                    <option value="WhatsApp Forward">WhatsApp Forward</option>
                    <option value="Reference (Friend/Family)">Reference (Friend/Family)</option>
                    <option value="Newspaper / Banner / Hoarding">Newspaper / Banner / Hoarding</option>
                    <option value="Our Proprenuer">Our Proprenuer</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              {
                SourceName === "Our Proprenuer" && (
                  <>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="d-flex" htmlFor="" style={{ fontSize: "14px" }}>Reference  Code</label>
                        <input
                          type="text"
                          placeholder="Reference Code"
                          className=" form-luxury text-uppercase"
                          value={RefrenceID}
                          onChange={(e) => setRefrenceID(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="d-flex" htmlFor="" style={{ fontSize: "14px" }}>Reference Name </label>
                        <input
                          type="text"
                          placeholder="Reference Name"
                          className=" form-luxury"
                          disabled
                          value={`${RefrenceID.length > 0 ? (`${User[0]?.id	 ? `${User[0]?.first_name} ${User[0]?.last_name}`	 : "No Person Found"}`) : "" }`}
                        />
                      </div>
                    </div>
                  </>
                )
              }
              
            </div>

            <div className="row">
              
            </div>

          </>
        }

        {
          step === 2 &&
          <>
            <div className="row">
              <div className="subTitle mb-3"> Pricing Details and Payment Plan Options </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>{Labels[0]?.label}<span className="text-danger">*</span> </label>
                  <select className="form-luxury"
                    value={type_size?.type}
                    onChange={(e) => setTypeSize(type_size_options?.find(item => item.type === e.target.value))}
                    style={{ padding: "20px 15px" }} >
                    <option value="">Select Type</option>
                    {
                      type_size_options?.map(item => {
                        return (
                          <option value={item.type}>{item.type}</option>
                        )
                      })
                    }
                  </select>
                  {errors.type && (
                    <span className=" text-danger span-error">{errors.type}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>{Labels[1]?.label} <span className="text-danger">*</span> </label>
                  <select className="form-luxury"
                    value={type_size?.size}
                    onChange={(e) => setTypeSize(type_size_options?.find(item => item.size === e.target.value))}
                    style={{ padding: "20px 15px" }} >
                    <option value="">Select Area</option>
                    {
                      type_size_options?.map(item => {
                        return (
                          <option value={item.size}>{item.size}</option>
                        )
                      })
                    }

                  </select>
                  {errors.size && (
                    <span className=" text-danger span-error">{errors.size}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Bank Details<span className="text-danger">*</span> </label>
                  <select className="form-luxury"
                    value={pay_method}
                    onChange={(e) => setPay_method(e.target.value)}
                    style={{ padding: "20px 15px" }} >
                    <option value="">Select Payment Option</option>
                    <option value="Bank Account">Bank Account</option>
                    <option value="Qr Code">Qr Code</option>
                  </select>
                  {errors.pay_method && (
                    <span className=" text-danger span-error">{errors.pay_method}</span>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Amount<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    disabled
                    className=" form-luxury h-100"
                    value={registrationAmount ? registrationAmount : content?.registration_amount}
                  />
                  {errors.amount && (
                    <span className=" text-danger span-error">{errors.amount}</span>
                  )}
                </div>
              </div>
            </div>

            {
              pay_method &&
              <div className="my-4 pb-4">
                {
                  pay_method === "Bank Account" ?
                    <div>
                      <h4 >Bank Details</h4>
                      <table className="table">
                        <tbody>
                          <tr>
                            <th>Bank Name</th>
                            <td>{content?.bank_name}</td>
                          </tr>
                          <tr>
                            <th>Account Number</th>
                            <td>{content?.account_no}</td>
                          </tr>
                          <tr>
                            <th> IFSE Code </th>
                            <td>{content?.ifsc_code}</td>
                          </tr>
                          <tr>
                            <th>Branch</th>
                            <td>{content?.branch}</td>
                          </tr>
                          <tr>
                            <th>Check In Favour</th>
                            <td>{content?.check_in_favour}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    :
                    <div>
                      <h4>Scan & Pay</h4>
                      <div className="project_qr ">
                        <img src={`${Config.imageUrl}/${content?.qr_code}`}
                          alt={content?.qr_code} className="w-100" />
                        {/* <div className="text-center mt-3 fw-bold">
                        QR CODE
                      </div> */}
                      </div>
                    </div>
                }

              </div>
            }

            <div className="row mt-4">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Ref. / UTR / RRN <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    placeholder="Enter Ref. / UTR / RRN"
                    onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    className=" form-luxury"
                    value={referenceNo}
                    onChange={(e) => setReferenceNo(e.target.value)}
                  />
                  {errors.referenceNo && (
                    <span className=" text-danger span-error">{errors.referenceNo}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Upload Payment Receipt</label>
                  <input
                    type="file"
                    style={{ padding: "12px 20px" }}
                    className="form-luxury pe-2"
                    onChange={(e) => setPaymentReceipt(e.target.files[0])}

                  />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="subTitle mb-3">Documents </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Aadhar Number {aadhar ? <span className="text-danger">*</span> : ""}</label>
                  <input
                    type="text"
                    placeholder="Enter Aadhar Number"
                    className=" form-luxury"
                    value={aadharNo}
                    required={aadhar !== null}
                    onChange={(e) => setAadharNo(e.target.value)}
                    maxLength={12}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.aadharNo && (
                    <span className=" text-danger span-error">{errors.aadharNo}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Upload Aadhar Card {aadharNo ? <span className="text-danger">*</span> : ""}</label>
                  <input
                    type="file"
                    className="form-luxury pe-2"
                    style={{ padding: "12px 20px" }}
                    onChange={(e) => handleUploadDocument(e, setAadhar, "aadhar", aadhar_ref)}
                    ref={aadhar_ref}
                  />
                  {errors.aadhar && (
                    <span className=" text-danger span-error">{errors.aadhar}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Pan Number  {pan ? <span className="text-danger">*</span> : ""}</label>
                  <input
                    type="text"
                    placeholder="Enter Pan Number"
                    className=" form-luxury"
                    value={panNo}
                    required={pan !== null}
                    onChange={(e) => setPanNo(e.target.value)}
                    maxLength={10}
                  />
                  {errors.panNo && (
                    <span className=" text-danger span-error">{errors.panNo}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="" style={{ fontSize: "14px" }}>Upload Pan Card {panNo ? <span className="text-danger">*</span> : ""}</label>
                  <input
                    type="file"
                    style={{ padding: "12px 20px" }}
                    className=" form-luxury pe-2"
                    // onChange={(e) => (e.target.files[0])}
                    onChange={(e) => handleUploadDocument(e, setPan, "pan", pan_ref)}
                    ref={pan_ref}

                  />
                  {errors.pan && (
                    <span className=" text-danger span-error">{errors.pan}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="my-4">
              <label htmlFor="" style={{ fontSize: "14px" }}>Message</label>
              <textarea
                type="text"
                placeholder="Enter Message"
                className=" form-luxury"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </>
        }

        <div className="d-flex justify-content-center align-items-center mt-5">
          {
            step === 1 && <button onClick={() => onHandleSubmit(false)} className="submit-btn w-auto" >Submit</button>
          }
          {
            step === 2 && <button type="button" onClick={() => setStep(1)} className="submit-btn w-auto">Back</button>
          }
          <button onClick={() => onHandleSubmit(step === 1 ? true : false)} className="submit-btn w-auto ms-3" style={{ backgroundColor: "#323032" }}>
            {step === 1 ? "Save & Next" : "Submit Now"}
            <i className="bi bi-arrow-right ms-4 fs-5 bg-white text-dark rounded-circle p-1 lh-sm"
            ></i>
          </button>
        </div>
      </div>
      {/* </form > */}
    </>
  );
};

export default PaymentForm;
