import React, { useEffect, useRef, useState } from "react";
import Banner from "../../Common/Banner";
import { Link } from "react-router-dom";
import { axiosInstance, Config } from "../../../config";
import { slugGenerator } from "../../../utils/SlugGenerator";
import moment from "moment";
import Loader from "../../Common/Loader";
import Pagination from "../../Common/Pagination";

const Blog = () => {
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [bannerDetail, setBannerDetail] = useState({});
  const [searchParams, setsearchParam] = useState("");
  const [filterData, setFilterData] = useState([]);

  const [fixedSearch, setFixedSearch] = useState(false);
  const lastRef = useRef(null)

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setFixedSearch((window.scrollY > 350 && window.scrollY < (lastRef?.current?.offsetTop-600)) ? true : false)
    })
  }, [])


  useEffect(() => {
    getBlogs();
  }, []);

  function getBlogs() {
    setLoading(true);
    axiosInstance
      .get(`blogs`)
      .then((res) => {
        setBlogs(res?.data?.blogs?.blog?.sort((a, b) => b.id - a.id));
        setFilterData(res?.data?.blogs?.blog?.sort((a, b) => b.id - a.id));
        setBannerDetail(res?.data?.blogs?.blog_bg)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }



  const [page, setPage] = useState(1);

  const [items_perpage, setItemsPerPage] = useState(10);

  const total_pages = Math.ceil(blogs?.length / items_perpage);

  const final_data = blogs?.slice(
    page * items_perpage - items_perpage,
    page * items_perpage
  );

  const changePage = (pagecount) => {
    if (pagecount > 0 && pagecount <= total_pages) {
      setPage(pagecount);
      window.scrollTo(0, 0);
    }
  };

  const onSearchBlog = (search_str) => {
    // e.preventDefault();
    setsearchParam(search_str)
    const searchQuery = search_str?.trim().toLowerCase();
    // Filter blogs based on partial match
    const searched = blogs?.filter((obj) =>
      obj.title?.toLowerCase().includes(searchQuery)
    );
    setFilterData(searched);
  };

  return (
    <div>
      {loading && <Loader />}

      <Banner subtitle={bannerDetail?.blog_section_subtitle} title={bannerDetail?.blog_section_title} backImage={bannerDetail?.blog_section_img} />

      <div className="section-padding bg-light-gold">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {final_data?.map((obj, i) => {
                return (
                  <div className="blog-posts mb-5">
                    <div className="blog-post">
                      <div className="position-relative blog-img">
                        <Link to={`${Config.appUrl}blog-detail/${obj.slug}`}>
                          <img
                            src={`${Config.imageUrl}/blogs/${obj.main_image}`}
                            alt="Blog Post 1"
                            className="w-100 object-fit-cover h-100"
                          />
                        </Link>
                        <div className="blog-date">
                          {moment(obj.created_at).format("MMM YYYY")} <br />
                          <span>{moment(obj.created_at).format("DD")}</span>
                        </div>
                      </div>
                      <div className="blog-title mt-3">
                        <Link
                          to={`${Config.appUrl}blog-detail/${obj.slug}`}
                          className="text-decoration-none"
                        >
                          <span className="blog-tags subTitle">{obj.tag} </span>
                        </Link>
                        <Link
                          to={`${Config.appUrl}blog-detail/${obj.slug}`}
                          className="text-decoration-none"
                        >
                          <h4 className="main-title mb-3 text-black">
                            {obj.title}
                          </h4>
                        </Link>
                      </div>
                      <p
                        style={{textAlign:'justify'}}
                        dangerouslySetInnerHTML={{
                          __html: obj?.content?.slice(0, 370),
                        }}
                      />
                      <span>...</span>
                      <div className="mt-2">
                        <Link
                          to={`${Config.appUrl}blog-detail/${obj.slug}`}
                          className="brochure-button bg-gold"
                        >
                          {bannerDetail?.blog_button_text} <i className=""></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
              <Pagination
                final_data={final_data}
                blogs={blogs}
                total_pages={total_pages}
                page={page}
                changePage={changePage}
              />
            </div>

            <div className={`col-md-4 ${fixedSearch && "fixed_search"}`}>
              <div className="sidebar">
                <div className="widget blog_search">
                  <form
                    className="position-relative"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      type="text"
                      placeholder="Search Blog"
                      className=" form-luxury mb-0"
                      value={searchParams}
                      onChange={(e) => onSearchBlog(e.target.value)}
                    />
                    <div className="icon-serach">
                      {searchParams?.length == 0 ? (
                        <i className={"bi bi-search"}></i>
                      ) : (
                        <i
                          className={"bi bi-x cursor-pointer"}
                          onClick={(e) => {
                            setsearchParam("");
                            setFilterData(blogs);
                          }}
                        ></i>
                      )}
                    </div>
                  </form>
                </div>
                <div className="widget widget_recent_posts mt-4">
                  <h4 className="widget-title">Recent Posts</h4>
                  {filterData?.slice(0, 3).map((recent, i) => {
                    return (
                      <div className="mt-2">
                        <div className="d-flex gap-3 ">
                          <div className="">
                            <Link
                              to={`${Config.appUrl}blog-detail/${recent.slug}`}
                            >
                              <img
                                src={`${Config.imageUrl}/blogs/${recent.main_image}`}
                                width="70"
                                className="object-fit-cover"
                              />
                            </Link>
                          </div>
                          <div>
                            <Link
                              to={`${Config.appUrl}blog-detail/${recent.slug}`}
                              className="text-decoration-none"
                            >
                              <h5>{recent.title}</h5>
                            </Link>
                            <p>
                              {moment(recent?.created_at).format(
                                "DD MMMM YYYY "
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div ref={lastRef} style={{ height: "2px" }}></div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
