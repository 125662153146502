import React, { useEffect, useState } from "react";
import { axiosInstance, Config } from "../../config";
import { Modal, notification } from "antd";
import ContactModal from "./ContactModal";
import CalendlyPopup from "./CalendlyPopup";
import Loader from "./Loader";

const MakeAppointment = ({ cta, projectName }) => {
  const [contact, setContact] = useState(false);
  const [Loading,setLoading] = useState(false);
  const [ProjectName,setProjectName] = useState('')
  const [Projects,setProjects] = useState([])

  useEffect(() => {
      getProjects();
  }, []);

  function getProjects() {
    setLoading(true);
    axiosInstance
      .get(`all-projects`)
      .then((res) => {
        setProjects(res?.data?.project_page?.project_info);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if(projectName){
      setProjectName(projectName)
    }
  },[projectName])

  const onHandleClick = async () => {

    if(!ProjectName){
      notification.error({
          message: (
            <div className="d-flex align-items-center">
              <div className="text-danger">Please Select Project to Visit</div>
            </div>
          ),
          placement:'top'
        });
      return;
    }

    setContact(true)
    try {
      const res = await axiosInstance.get(`count/${'schedule_visit'}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const description = cta?.cta_section_button_text?.replace("$project", projectName != undefined ? projectName : " Project")

  return (
    <div>
      {Loading && <Loader/>}
      <div
        className="cta-section section-padding backgroun-no-repeat background-size-cover"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(${Config.imageUrl}/${cta?.cta_bg})`,
          backgroundBlendMode: 'darken',
          backgroundRepeat: "no-repeat",

          backgroundPosition: "top center"
        }}
      >
        <div className="container mt-4 mb-4">
          <div className="text-center">
            <h2 className="text-center">{cta?.cta_section_text}</h2>
            <p className="col-md-10 mb-5 d-flex align-items-center" style={{textAlign:'justify'}} dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <div className="d-flex justify-content-center gap-4 mt-4">
            <div className="w-25">
              <select className="form-luxury fs-6 fw-bold h-100"
                value={ProjectName}
                onChange={(e) => {
                  setProjectName(e.target.value)
                }}
                style={{ backgroundColor:"rgba(255,255,255,0.5)", padding: "20px 15px" , border:'1px solid white' }} >
                <option value="">Select Project for Visit</option>
                {
                  Projects.length > 0 && Projects.map((item) => (
                    <option value={item?.project_name	}>{item?.project_name	}</option>
                  ))
                }
              </select>
            </div>
            <div className="text-center">
              <button
                className="submit-btn fw-bold border-white d-flex align-items-center text-white bg-gold h-100"
                onClick={onHandleClick}
              >
                {projectName != undefined ? (
                  <>
                    {cta?.cta_section_button_url}
                  </>
                ) : (
                  <>
                    {cta?.cta_section_button_url}
                  </>
                )}
                <i className="bi bi-arrow-right ms-4 fs-5 rounded-circle move_arrow"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
        <Modal
          title={`Schedule a Visit ${ProjectName ? `for ${ProjectName}` : ''}`}
          open={contact}
          onCancel={() => setContact(false)}
          className="contact-modal"
          footer={null}
          width={1100}
        >
          <CalendlyPopup projectName={ProjectName}/>
        </Modal>
    </div >
  );
};

export default MakeAppointment;
