import React, { useEffect, useState } from "react";
import Banner from "../../Common/Banner";
import ProjectCard from "../../Common/ProjectCard";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../config";
import Loader from "../../Common/Loader";
import Pagination from "../../Common/Pagination";
import { revertSlug } from "../../../utils/SlugGenerator";

const Project = () => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [projects, setProjects] = useState([]);
  const [headerImg, setheaderImg] = useState('');
  const [project_title, setProjectTitle] = useState('');

  useEffect(() => {
    getBlogs();
  }, [params?.projects]);
  function getBlogs() {
    setLoading(true);
    axiosInstance
      .get(`locations/${params?.projects}`)
      .then((res) => {
        setProjects(res?.data?.projects);
        setheaderImg(res?.data?.location_page?.location[0]?.img_bg)
        setProjectTitle(res?.data?.location_bg)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  const [page, setPage] = useState(1);
  const [items_perpage, setItemsPerPage] = useState(9);
  const total_pages = Math.ceil(projects?.length / items_perpage);
  const final_data = projects?.slice(
    page * items_perpage - items_perpage,
    page * items_perpage
  );
  const changePage = (pagecount) => {
    if (pagecount > 0 && pagecount <= total_pages) {
      setPage(pagecount);
      // window.scrollTo(0, 400);
      window.scrollTo({
        top: 400,
        left: 0,
        behavior: "instant", // Optional if you want to skip the scrolling animation
      });
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Banner subtitle={"Project Location"} title={`${revertSlug(params?.projects)} Projects`} backImage={headerImg} />

      <div className="">
        <div className=" bgblack">
          <div className="container">
            <div className="section-padding">
              <div className="featureprojects">
                <span className="subTitle text-capitalize">{project_title?.detail_title}</span>
                <h2 className="text-capitalize">{project_title?.detail_subtitle}</h2>
                <div className="projects">
                  <ProjectCard projectsData={final_data} />
                </div>
              </div>
              <Pagination
                final_data={final_data}
                blogs={projects}
                total_pages={total_pages}
                page={page}
                changePage={changePage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
