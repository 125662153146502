import { useState, useMemo } from "react";


const ValueCalculator = ({ CurrentValue, Terms }) => {
    // Separate quantities for each category
    const [otherChargesQuantities, setOtherChargesQuantities] = useState([]);
    const [additionalChargesQuantities, setAdditionalChargesQuantities] = useState([]);
    const [governmentChargesQuantities, setGovernmentChargesQuantities] = useState([]);

    const termsDescriptions = Terms?.Terms_description?.split('$');
    const termsValues = Terms?.Terms_value?.split('$');
    const termstype = Terms?.Terms_type?.split('$');

    const filteredTerms = (type) => {
        return termsDescriptions?.map((desc, index) => {
            const termType = termstype[index]; // Get the type of the current term
            if (termType === type) {
                return {
                    desc, 
                    value: termsValues[index] || '0',
                    type: termType
                };
            }
            return null;
        }).filter(item => item !== null); // Remove null values
    };

    const handleQuantityChange = (type, index, change) => {
        console.log(type,index,change);
        if (type == '1') {
            setOtherChargesQuantities((prev) => {
                const updatedQuantities = [...prev];
                updatedQuantities[index] = Math.max(1, (updatedQuantities[index] || 1) + change);
                return updatedQuantities;
            });
        }
        else if (type == '2') {
            setAdditionalChargesQuantities((prev) => {
                const updatedQuantities = [...prev];
                updatedQuantities[index] = Math.max(1, (updatedQuantities[index] || 1) + change);
                return updatedQuantities;
            });
        }
        else if (type == '3') {
            setGovernmentChargesQuantities((prev) => {
                const updatedQuantities = [...prev];
                updatedQuantities[index] = Math.max(1, (updatedQuantities[index] || 1) + change);
                return updatedQuantities;
            });
        }
    };    

    const calculateTotalForType = (type) => {
        let quantities = [];
        let termsDescriptions = [];
        let termsValues = [];
        
        if (!termstype || !Array.isArray(termstype)) {
            console.error('termstype is undefined or not an array');
            return 0; // Return 0 or handle the error as needed
        }

        // Filter data based on type
        if (type === '1') {
            termsDescriptions = filteredTerms('1'); // Filter terms based on type 1
            termsValues = Terms?.Terms_value?.split('$')?.filter((_, idx) => termstype[idx] === '1'); // Filter based on type 1
            quantities = otherChargesQuantities; // Assuming `otherChargesQuantities` is already filtered
        } else if (type === '2') {
            termsDescriptions = filteredTerms('2'); // Filter terms based on type 2
            termsValues = Terms?.Terms_value?.split('$')?.filter((_, idx) => termstype[idx] === '2'); // Filter based on type 2
            quantities = additionalChargesQuantities; // Assuming `additionalChargesQuantities` is already filtered
        } else if (type === '3') {
            termsDescriptions = filteredTerms('3'); // Filter terms based on type 3
            termsValues = Terms?.Terms_value?.split('$')?.filter((_, idx) => termstype[idx] === '3'); // Filter based on type 3
            quantities = governmentChargesQuantities; // Assuming `governmentChargesQuantities` is already filtered
        }
    
        // Calculate the final total for the given type
        const total = termsDescriptions?.reduce((sum, desc, index) => {
            const termValue = termsValues[index] || '0'; // Default to '0' if value is missing
            const baseValue = parseFloat(termValue.replace(/,/g, "")) || 0; // Ensure valid number
            const quantity = quantities[index] || 1; // Default quantity to 1
            const finalValue = baseValue * quantity;
    
            return sum + finalValue; // Add up the final value for each term
        }, 0);
    
        return isNaN(total) ? 0 : total;
    };    


    const Charges = filteredTerms('2')?.reduce((total, item) => {
        const { value } = item;
        const baseValue = parseFloat(value.replace(/,/g, "")) || 0; // Ensure valid number
        const multiplier = CurrentValue?.find((item) => item?.sequence?.toLowerCase()?.includes("a12"))?.value || 1; // Default to 1 if undefined
    
        let finalValue;
        if(item?.desc.includes("Sq")){
            finalValue = baseValue * multiplier
        }else{
            finalValue = baseValue
        }
       
    
        return total + finalValue;
    }, 0);

    const SafeCharges = isNaN(Charges) ? 0 : Charges;    

    const totalGovernmentCharges = filteredTerms('3')?.reduce((total, item) => {
        const { value } = item;
        const baseValue = parseFloat(value.replace(/,/g, "")) || 0; // Ensure valid number
    
        const finalvalue = (baseValue * CurrentValue[2]?.value) / 100;
        return total + finalvalue;
    }, 0);
    
    const safeTotalGovernmentCharges = isNaN(totalGovernmentCharges) ? 0 : totalGovernmentCharges;    

    const overallTotal = useMemo(() => {
        return parseFloat(CurrentValue[2]?.value.replace(/,/g, "")) + calculateTotalForType('1') + SafeCharges + safeTotalGovernmentCharges;
    }, [otherChargesQuantities, additionalChargesQuantities, governmentChargesQuantities , CurrentValue]);

    return (
        <div className="table-responsive mt-3 Calculator-div ps-3">
            <table className="fw-bold w-100 Calculator-Table">
                <h5 className="fw-bold mb-3">
                    <i className="bi bi-arrow-right-circle-fill me-2"></i> Unit Basic Sale Value (A)
                </h5>
                <tr>
                    <td className="pe-3 col-xl-9">{CurrentValue?.find((item) => item?.sequence?.toLowerCase()?.includes("a12"))?.label_name}</td>
                    <td>{CurrentValue?.find((item) => item?.sequence?.toLowerCase()?.includes("a12"))?.value}</td>
                </tr>
                <tr>
                    <td className="pe-3">{CurrentValue?.find((item) => item?.sequence?.toLowerCase()?.includes("a13"))?.label_name}</td>
                    <td>
                        {CurrentValue?.find((item) => item?.sequence?.toLowerCase()?.includes("a13"))?.value}
                    </td>
                </tr>
                <tr>
                    <td className="pe-3 fs-6">{CurrentValue[2]?.label_name}</td>
                    <td className="fs-6">
                        <i className="bi bi-currency-rupee"></i>
                        {
                            new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(CurrentValue[2]?.value)
                        }
                    </td>
                </tr>
                {/* <tr>
                    <td className="pe-3 fs-6">Total</td>
                    <td className="fs-6">
                        <i className="bi bi-currency-rupee"></i>
                        {
                            new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(CurrentValue[2]?.value)
                        }
                    </td>
                </tr> */}
                <tr>
                    <div style={{ columnSpan: "2" }} className="Line"></div>
                </tr>

                {/* Other Charges */}
                <h5 className="mb-3 fw-bold">
                    <i className="bi bi-arrow-right-circle-fill me-2"></i> Other Charges (B)
                </h5>
                {
                    filteredTerms('1')?.map((item, index) => {
                        const { desc, value, type } = item;
                        const baseValue = parseFloat(value.replace(/,/g, ""));
                        const quantity = otherChargesQuantities[index] || 1;
                        const finalValue = baseValue * quantity;

                        const replacedDescription = desc.replace("{value}", value);

                        return (
                            <tr key={index}>
                                <td className="d-flex justify-content-between align-items-center pe-4 pb-2">
                                    {replacedDescription}
                                    <div className="d-flex align-items-center Quntity-Button">
                                        <button
                                            className="btn btn-sm me-2"
                                            onClick={() => handleQuantityChange(type, index, -1)}
                                        >
                                            <i className="bi bi-dash-lg fs-6"></i>
                                        </button>
                                        <span className="">{quantity}</span>
                                        <button
                                            className="btn btn-sm ms-2"
                                            onClick={() => handleQuantityChange(type, index, 1)}
                                        >
                                            <i className="bi bi-plus-lg fs-6"></i>
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <i className="bi bi-currency-rupee"></i>
                                    {new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(finalValue) }
                                </td>
                            </tr>
                        );
                    })
                }
                <tr>
                    <td className="pe-3 fs-6">Total</td>
                    <td className="fs-6">
                        <i className="bi bi-currency-rupee"></i>
                        {
                            new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(calculateTotalForType('1'))
                        }
                    </td>
                </tr>

                <tr>
                    <div style={{ columnSpan: "2" }} className="Line"></div>
                </tr>

                {/* Additional Charges */}
                <h5 className="mb-3 fw-bold">
                    <i className="bi bi-arrow-right-circle-fill me-2"></i> Additional Charges (C)
                </h5>
                {
                    filteredTerms('2')?.map((item, index) => {
                        const { desc, value, type } = item;
                        const baseValue = parseFloat(value.replace(/,/g, ""));
                        let finalValue;
                        if(item?.desc.includes("Sq")){
                            finalValue = baseValue * (CurrentValue?.find((item) => item?.sequence?.toLowerCase()?.includes("a12"))?.value)
                        }else{
                            finalValue = baseValue
                        }

                        const replacedDescription = desc.replace("{value}", value);

                        return (
                            <tr key={index}>
                                <td className="d-flex justify-content-between align-items-center pe-4 pb-2">
                                    {replacedDescription}
                                </td>
                                <td>
                                    <i className="bi bi-currency-rupee"></i>
                                    {new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(finalValue)}
                                </td>
                            </tr>
                        );
                    })
                }
                <tr>
                    <td className="pe-3 fs-6">Total</td>
                    <td className="fs-6">
                        <i className="bi bi-currency-rupee"></i>
                        {
                            new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(SafeCharges)
                        }
                    </td>
                </tr>

                <tr>
                    <div style={{ columnSpan: "2" }} className="Line"></div>
                </tr>

                <h5 className="mb-3 fw-bold">
                    <i className="bi bi-arrow-right-circle-fill me-2"></i> Government Charges (D)
                </h5>
                {
                    filteredTerms('3')?.map((item, index) => {
                        const { desc, value, type } = item;
                        const baseValue = parseFloat(value.replace(/,/g, ""));
                        const finalValue = (baseValue * CurrentValue[2]?.value) / 100;

                        const replacedDescription = desc.replace("{value}", value);

                        return (
                            <tr key={index}>
                                <td className="d-flex justify-content-between align-items-center pe-4 pb-2">
                                    {replacedDescription}
                                </td>
                                <td>
                                    <i className="bi bi-currency-rupee"></i>
                                    {new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(finalValue)}
                                </td>
                            </tr>
                        );
                    })
                }

                {/* Total Calculation for Government Charges */}
                <tr>
                    <td className="pe-3 fs-6">Total Government Charges</td>
                    <td className="fs-6">
                        <i className="bi bi-currency-rupee"></i>
                        {new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(safeTotalGovernmentCharges)}
                    </td>
                </tr>

                <tr>
                    <div style={{ columnSpan: "2" }} className="Line"></div>
                </tr>

                {/* Overall Total Calculation */}
                <tr>
                    <td className="pe-3 fs-5">Overall Total (A + B + C + D)</td>
                    <td className="fs-5">
                        <i className="bi bi-currency-rupee"></i>
                        {new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(overallTotal)}
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default ValueCalculator;

