import React, { useEffect, useState } from "react";
import Banner from "../../Common/Banner";
import { Link, useParams } from "react-router-dom";
import { revertSlug } from "../../../utils/SlugGenerator";
import User1 from "../../../assets/Images/user-info.png";
import { axiosInstance, Config } from "../../../config";
import moment from "moment";
import Loader from "../../Common/Loader";
import { notification } from "antd";

const BlogDetails = () => {
  const slug = useParams();

  const [loading, setLoading] = useState(false);
  const [blog_details, setBlogDetails] = useState({});
  const [blog_banner_details, setBlogBannerDetails] = useState({});
  const [latestBlog, setLatestBlog] = useState([]);
  const [comments, setComments] = useState([]);
  useEffect(() => {
    getBlogs();
  }, [slug]);
  function getBlogs() {
    setLoading(true);
    axiosInstance
      .get(`blogs/${slug.slug}`)
      .then((res) => {
        setComments(res?.data?.blog_page?.comments);
        setLatestBlog(res?.data?.blog_page?.latestBlog);
        setBlogDetails(res?.data?.blog_page?.blog);
        setBlogBannerDetails(res?.data?.blog_page?.blog_detail_bg);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const validation1 = () => {
    const newErrors = {};

    if (!name) {
      newErrors.fname = "Name is required";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }

    if (!phone) {
      newErrors.phone = "Phone Number is required";
    } else if (phone.length > 15 || phone.length < 7) {
      newErrors.phone = "Invalid Phone Number length";
    }

    if (!message) {
      newErrors.health_problem = "Message is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    if (validation1()) {
      setLoading(true);
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("message", message);
        formData.append("type", 0);
        formData.append("project", '');
        formData.append("section", "Blog");
        const res = await axiosInstance.post("contactdetails", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.status === 200) {
          setName("");
          setPhone("");
          setMessage("");
          setEmail("");
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const [comment_name, setCommentName] = useState("");
  const [comment_email, setCommentEmail] = useState("");
  const [comment_message, setCommentMessage] = useState("");
  const [errors2, setErrors2] = useState({});

  const validation2 = () => {
    const newErrors = {};

    if (!comment_name) {
      newErrors.comment_name = "Name is required";
    }

    if (!comment_email) {
      newErrors.comment_email = "Email is required";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(comment_email)
    ) {
      newErrors.comment_email = "Invalid email format";
    }

    if (!comment_message) {
      newErrors.comment_message = "Message is required";
    }

    setErrors2(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onAddComment = async (e) => {
    e.preventDefault();
    if (validation2()) {
      setLoading(true);
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("blog_id", blog_details.id);
        formData.append("name", comment_name);
        formData.append("email", comment_email);
        formData.append("feedback", comment_message);
        formData.append("blog_name", blog_details?.name);
        const res = await axiosInstance.post("comments", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.status == 200) {
          notification.open({
            message: (
              <div className="d-flex align-items-center">
                <div className="pr-20">
                  <i
                    className="bi bi-check2-circle text-success me-2"
                    style={{ fontSize: "20px" }}
                  ></i>
                </div>
                <div className="succes-message">
                  Your Comment has been Added
                </div>
              </div>
            ),
            description: "Comments Added Successfully",
            placement:'top'
          });
          setComments((prevItems) => [...prevItems, res?.data?.data]);
          setCommentEmail("");
          setCommentMessage("");
          setCommentName("");
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Banner subtitle={revertSlug(slug?.slug)} title={blog_banner_details?.rss_details_title}
        backImage={blog_banner_details?.rss_bg} />
      <div className="">
        <div className="section-padding bg-light-gold">
          <div className="container">
            <div className="row">
              <div className="col-md-8 pe-5">
                <div className="blog-posts mb-5">
                  <div className="blog-post">
                    <div className="position-relative blog-img">
                      <img
                        src={`${Config.imageUrl}/blogs/${blog_details.main_image}`}
                        alt="Blog Post 1"
                        className="w-100 object-fit-cover h-100"
                      />


                      <div className="blog-date">
                        {moment(blog_details.created_at).format("MMM YYYY")}{" "}
                        <br />
                        <span>
                          {moment(blog_details.created_at).format("DD")}
                        </span>
                      </div>
                    </div>

                    <div className="blog-title mt-3">
                      <span className="blog-tags subTitle">
                        {blog_details?.bcategory_id}
                      </span>
                      <div className="d-md-flex justify-content-between">
                        <div>
                          <h4 className="main-title mb-3 text-black">
                            {blog_details?.title}
                          </h4>
                        </div>

                        <div style={{minWidth: "100px"}}> 
                          <div className="d-inline-block border px-3 py-2 border-dark text-green mb-md-0 mb-4">
                            Views : {blog_details?.views}
                          </div>
                        </div>

                      </div>
                    </div>
                    <div
                      style={{textAlign:'justify'}}
                      dangerouslySetInnerHTML={{
                        __html: blog_details?.content,
                      }}
                    />
                    <div className="mt-4">
                      <div>
                        <ul class="blog-tags">
                          {blog_details?.tags?.split(',')?.map((tag, i) => {
                            return (
                              <li key={i + "tag"}>
                                <a>{tag}</a>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="comments mt-4 pt-4">
                      <h3 className="main-title fs-3 mb-4">All Comments</h3>
                      {comments?.length == 0 && (
                        <p>
                          No Comment has been Added, Be the First Commenter.
                        </p>
                      )}


                      {comments?.map((comment) => {
                        return (
                          <div className="d-flex gap-3 mt-3">
                            <div>
                              <img src={User1} alt="No Img" className="" />
                            </div>
                            <div className="comment-text">
                              <div className="d-flex gap-3 align-items-center">
                                <h5 className="mb-0">{comment?.name}</h5>
                                <span className="">
                                  {moment(comment?.created_at).format(
                                    "DD MMMM YYYY"
                                  )}
                                </span>
                              </div>
                              <p>{comment?.feedback}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-10 pt-4">
                      <h3 className="main-title fs-3 mb-4 mt-4">
                        Leave a Comments
                      </h3>
                      <form onSubmit={onAddComment}>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <input
                              type="text"
                              placeholder="Enter Full Name"
                              className=" form-luxury bg-white"
                              value={comment_name}
                              onChange={(e) => setCommentName(e.target.value)}
                            />
                            {errors2.comment_name && (
                              <span className=" text-danger span-error">
                                {errors2.comment_name}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <input
                              type="text"
                              placeholder="Enter Email Address"
                              className=" form-luxury bg-white"
                              value={comment_email}
                              onChange={(e) => setCommentEmail(e.target.value)}
                            />
                            {errors2.comment_email && (
                              <span className=" text-danger span-error">
                                {errors2.comment_email}
                              </span>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <textarea
                              type="text"
                              placeholder="Message Here"
                              className=" form-luxury bg-white"
                              value={comment_message}
                              onChange={(e) =>
                                setCommentMessage(e.target.value)
                              }
                            />
                            {errors2.comment_message && (
                              <span className=" text-danger span-error">
                                {errors2.comment_message}
                              </span>
                            )}
                          </div>
                          <div className="mt-2">
                            <button className="brochure-button bg-gold">
                              Add Comment <i className=""></i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 pe-0 ps-3">
                <div className="sidebar">
                  <div className="widget widget_recent_posts mt-4">
                    <h4 className="widget-title">Recent Posts</h4>
                    {latestBlog?.map((recent, i) => {
                      return (
                        <div className="mt-2">
                          <div className="d-flex gap-3 ">
                            <div className="">
                              <Link
                                to={`${Config.appUrl}blog-detail/${recent.slug}`}
                              >
                                <img
                                  src={`${Config.imageUrl}/blogs/${recent.main_image}`}
                                  width="70"
                                  className="object-fit-cover"
                                />
                              </Link>
                            </div>
                            <div>
                              <Link
                                to={`${Config.appUrl}blog-detail/${recent.slug}`}
                                className="text-decoration-none"
                              >
                                <h5 className="text-decoration-none">{recent.title}</h5>
                              </Link>
                              <p>
                                {moment(recent.created_at).format("DD MMMM YYYY")}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="widget widget_recent_posts mt-4">
                    <h4 className="widget-title">Get In Touch</h4>
                    <form onSubmit={onHandleSubmit}>
                      <div className="mb-3">
                        <input
                          type="text"
                          placeholder="Enter Full Name *"
                          className=" form-luxury"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          onKeyPress={(e) => {
                            if (/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          required
                        />
                        {errors.fname && (
                          <span className=" text-danger span-error">
                            {errors.fname}
                          </span>
                        )}
                      </div>
                      <div className="mb-3">
                        <input
                          type="email"
                          placeholder="Enter Email Address *"
                          className=" form-luxury"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        {errors.email && (
                          <span className=" text-danger span-error">
                            {errors.email}
                          </span>
                        )}
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          placeholder="Enter Phone Number *"
                          className=" form-luxury"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {errors.phone && (
                          <span className=" text-danger span-error">
                            {errors.phone}
                          </span>
                        )}
                      </div>
                      <div className="mb-3">
                        <textarea
                          type="text"
                          placeholder="Enter Message *"
                          className=" form-luxury"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                        />
                        {errors.message && (
                          <span className=" text-danger span-error">
                            {errors.message}
                          </span>
                        )}
                      </div>
                      <div>
                        <button className="submit-btn">Submit Request</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default BlogDetails;
